.games_category_list {

}

.games_category_list_title {
    margin-left: 20px;
}

.games_list {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    gap: 10px;
    scrollbar-color: transparent transparent;
    padding: 20px;
}
