.container {
    width: 100%;
    height: 100%;
}

.container > .loading_overlay {
    /* We'll render the overlay on top of the Unity Application. */
    width: 100%;
    height: 100%;
    background: #2e2e65;
    /* We'll set the following Flex properties in order to center the text. */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
    opacity: 1;
}

.container > .unity {
    /* The Unity Application matches it size to the container. */
    width: 100%;
    height: 100%;
}