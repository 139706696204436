.container {
    width: 100%;
    height: 100%;
    background-color: #13133b;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container * {
    color: white;
}

.logo {
    width: max-content;
    padding-left: 20px;
    align-self: flex-start;
}

.card {
    max-width: 800px;
    margin: auto;
    min-height: 800px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 25px;
    display: flex;
}

.top_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
}

.bottom_container {
    flex: 1;
    background-color: black;
    width: 100%;
    padding: 20px;
}

.title {
    margin: 20px;
    font-size: 1.5em;
}

.preview {
    overflow: hidden;
    aspect-ratio: 4/3;
    width: 80%;
    border-radius: 16px;
}

.image {
    width: 100%;
    height: 100%;
}

.button {
    display: inline-block;
    padding: 5px 15px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    background-color: #8080fa;
    border: none;
    border-radius: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button:hover {
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 10px;
}

.button:active {
    transform: translateY(2px);
}

.description_header {
    font-size: 1.5em;
}
.description {
    margin-top: 20px;
    font-size: 0.8em;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.8);
}

.game {
    border-radius: 16px;
    max-width: 800px;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .logo {
        width: max-content;
        align-self: center;
        padding-left: 0px;
    }

    .card {
        border-radius: 0px;
        box-shadow: transparent 0px 0px 25px;
        border-top: solid rgba(255, 255, 255, 0.25) 1px;
        margin: 5px;
    }

    .game {
        margin-top: 5px;
        border-radius: 0px;
    }
}
