.main {
    background-color: #13133b;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.nav_panel {
    width: 200px;
    border-right: solid 1px rgba(255, 255, 255, 0.25);
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.top_menu_list {
    border-top: solid rgba(255, 255, 255, 0.25) 1px;
    padding-top: 20px;
}

.top_menu_list li a {
    height: 32px;
    text-align: center;
    vertical-align: center;
    border-radius: 8px;
    line-height: 32px;
    display: block;
    font-size: 1em;
}

.top_menu_list li a.active {
    background-color: rgb(48, 40, 86);
}
.top_menu_list li a:hover {
    background-color: rgb(65, 57, 108);
}

.top_menu_list li:not(:last-child) {
    margin-bottom: 10px;
}

.categories_list {
    margin-top: 30px;
    flex-grow: 1;
    overflow: auto;

    font-size: 0.9em;
    padding-left: 10px;
}

.categories_list li a:hover {
    color: rgb(143, 137, 176);
}

.categories_list li a.active {
    color: rgb(143, 137, 176);
}

.categories_list li:not(:last-child) {
    margin-bottom: 10px;
}

.additional_menu_list {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    border-top: solid rgba(255, 255, 255, 0.25) 1px;
}

.additional_menu_list li a {
    font-size: 0.7em;
    color: rgb(143, 137, 176);
}

.additional_menu_list li a:hover {
    color: white;
}

.copyright {
    font-size: 0.7em;
    margin-bottom: 20px;
    color: rgba(118, 113, 171, 0.51);
}

.main_content {
    width: calc(100% - 200px);
    overflow: auto;
    padding-top: 20px;
}

.contact_us {
    margin: 20px;
}

.privacy_policy {
    margin: 20px;
}

.menu_button_container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu_button {
}

#menu_toggle {
    align-self: flex-start;
    display: none;
}

.menu_button,
.menu_button::before,
.menu_button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu_button::before {
    content: '';
    margin-top: -8px;
}

.menu_button::after {
    content: '';
    margin-top: 8px;
}

#menu_toggle:checked + .menu_button_container .menu_button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu_toggle:checked + .menu_button_container .menu_button {
    background: rgba(255, 255, 255, 0);
}

#menu_toggle:checked + .menu_button_container .menu_button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}

.menu_lists_container {

}

@media screen and (max-width: 600px) {
    .main {
        flex-direction: column;
    }
    .nav_panel {
        width: 100%;
    }
    .additional_menu_list li a {
        font-size: 0.7em;
    }
    .main_content {
        width: 100%;
        overflow: auto;
        padding-top: 10px;
    }

    .menu_button_container {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        height: auto;
        margin-top: 35px;
        margin-left: 25px;
    }

    .menu_lists_container {
        transition: max-height 400ms ease-out;
        max-height: 0;
        overflow: hidden;
    }

    #menu_toggle:checked ~ .menu_lists_container {
        transition: max-height 400ms ease-in;
        max-height: 1000px;
    }

    .top_menu_list {
        border-top: solid rgba(255, 255, 255, 0.25) 1px;
        padding-top: 20px;
        padding-left: 10px;
        margin-top: 5px;
    }

    .top_menu_list li a {
        font-size: 1.2em;
        text-align: left;
    }

    .top_menu_list li a.active {
        background-color: transparent;
        color: rgb(143, 137, 176);
    }
    .top_menu_list li a:hover {
        background-color: transparent;
        color: rgb(143, 137, 176);
    }

    .top_menu_list li:not(:last-child) {
        margin-bottom: 0px;
    }

    .categories_list {
        margin-top: 10px;
        flex-grow: 1;
        overflow: auto;

        font-size: 0.9em;
        padding-left: 10px;
    }

    .categories_list li a:hover {
        color: rgb(143, 137, 176);
    }

    .categories_list li a.active {
        color: rgb(143, 137, 176);
    }

    .categories_list li:not(:last-child) {
        margin-bottom: 10px;
    }

    .copyright {
        margin-bottom: 10px;
    }
}

