.game_grid_item {
    flex-shrink: 0;
    display: block;
    border-radius: 16px;
    aspect-ratio: 4/3;
    overflow: hidden;
    position: relative;
}

.small {
    width: 20%;
}

.medium {
    width: 30%;
}

.big {
    width: 40%;
}

.game_grid_item img {
    width: 100%;
    height: 100%;
}
.game_grid_item_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(3, 0, 0, 0.6);
}

.game_grid_item:hover .game_grid_item_overlay {
    opacity: 1;
}

.game_grid_item_title {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 10px;
}

@media screen and (max-width: 600px) {
    .small {
        width: 60%;
    }

    .medium {
        width: 75%;
    }

    .big {
        width: 90%;
    }
}